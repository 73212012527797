/*
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');

@import "./variables.styles";

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $primary-font-size;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  font-size: $header-font-size;
  line-height: $header-line-height;
  color: $h1-color;
  font-weight: 700;
  margin-bottom: 10px;
}

h2{
  font-size: $subheader-font-size;
  line-height: $subheader-line-height;
  font-weight: 700;
  color: $h2-color;
  margin-bottom: 10px;
  letter-spacing: inherit;
}

p{
  font-size: $paragraph-font-size;
  line-height: $paragraph-line-height;
  font-weight: 400;
  color: $p-color;
  margin-bottom: 8px;
  letter-spacing: 0.16px;
}

p.small{
  font-size: $small-font-size;
  line-height: 16px;
  font-weight: $label-font-weight;
  color: #707070;
  letter-spacing: 0;
}

.black {
  font-size: 10px;
  color: #0F212E;
  padding-left: 10px;
}

a {
  color: $primary-color;
  text-decoration: none;
}

input, textarea, keygen, select, button{
  font-size: $secondary-font-size;
}

.text-center{
  text-align: center;
}

.font-size-20{
  font-size: 20px;
}

.mb-32{
  margin-bottom: 32px;
}

.mb-40{
  margin-bottom: 40px;
}

.mb-16{
  margin-bottom: 16px;
}

.mb-19{
  margin-bottom: 19px;
}

.mb-12{
  margin-bottom: 12px;
}

.mb-13{
  margin-bottom: 13px;
}

.mt-24{
  margin-top: 24px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-16 {
  margin-top: 16px;
}
.py-24{
  padding-top: 24px;
  padding-bottom: 24px;
}

.py-32{
  padding-top: 32px;
  padding-bottom: 32px;
}

.mb-25{
  margin-bottom: 25px;
}

.mb-55{
  margin-bottom: 55px;
}

.mt-32{
  margin-top: 32px;
}

.my-30{
  margin-top: 30px;
  margin-bottom: 30px;
}

.my-32{
  margin-top: 32px;
  margin-bottom: 32px;
}

.mt-18{
  margin-top: 18px;
}

.text-color {
  color: #008ac8;
  margin-left: 2px;
  margin-right: 2px;
}
.icon {
  vertical-align: middle;
  display: inline-block;
}
.form-section-title{
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.15px;
  color: #616161;
}
.color-blue{
  color: $primary-color;
}
.cursor-pointer{
  cursor: pointer;
}
.border-line {
  background-color: #ffffff !important;
}

.welcome-text {
  margin-bottom: 60px;
}
.sign-phase-radio-button {
  padding: 12px 0 33px 8px;
}
.color-gray{
  background-color: $p-color;
}
.timer {
  padding-top: 24px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0;
}
p {
  strong {
    color: $h1-color;
  }
}
input[type='number']{
  font-family: 'Poppins', sans-serif;
}

.est-balance {
  font-size: 24px;
  color: $primary-color;
  font-weight: 700;
  line-height: 28px;
}

.bx--accordion__item--active {
  .bx--accordion__title {
    color: $primary-color;
  }
}
.bx--accordion__heading {
  &:focus{
    &:before {
      border: none;
    }
  }
}
.item-divider {
  border: 1px solid whitesmoke;
  width: 100%;
  margin-bottom: 5px;
}

.inherit-color{
  color: inherit;
}

.underline-bold {
  border-bottom: 6px solid #008AC8;
}

.hide-input {
  height: 0;
  widows: 0;
  display: none !important;
}

.text-bold{
  font-weight: $label-font-weight;
}
.border-line {
    border-width: 0px;
    border-top: 1px solid lightgrey;
}

@media (max-width: 767px){
  .fixed-bottom{
    margin-top: 0;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 999;
    bottom: 0;
    padding: 17.5px 32px;
    background: #fff;
  }
}


