@import "./variables.styles";

.bx--fieldset{
  margin-bottom: 16px;
}
.bx--label:empty{
  display: none;
}
.bx--accordion__heading {
  width: 100%;
}
.bx--accordion__item	{
  //border-top: 0px;
}
.bx--accordion__item:first-child {
  border-top: 0px;
}

.bx--accordion__title {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #0f173a;
}
.bx--accordion__arrow {
  width: 251px;
  height: 25px;
  color: #0f173a;
}
.bx--inline-notification {
  max-width: 100% !important;
}

.bx--inline-notification--success {
  background-color: #158bc6;
}
.bx--label{
  font-weight: $label-font-weight;
}
.bx--radio-button__label {
  font-weight: 500;
  font-size: 14px;
}
.bx--text-input,
.bx--select-input,
.bx--list-box__label{
  font-weight: $input-font-weight;
  line-height: 1.4;
}
.bx--radio-button-group--vertical .bx--radio-button-wrapper:not(:last-of-type){
  margin-bottom: 1rem;
}
.bx--text-input--invalid {
  outline: 1px solid $alert-color;
  outline-offset: 0;
  border-bottom: 0px;
  background-color: #FFF1F1;
}
input[data-invalid], .bx--text-input__field-wrapper[data-invalid], .bx--text-area__wrapper[data-invalid] > .bx--text-area--invalid, .bx--select-input__wrapper[data-invalid], .bx--list-box[data-invalid], .bx--combo-box[data-invalid] .bx--text-input {
  outline: 1px solid $alert-color;
  outline-offset: -1px;
}
@mixin transition($what: all, $time: 0.5s, $how: ease-in-out) {
  -webkit-transition: $what $time $how;
  -moz-transition:    $what $time $how;
  -ms-transition:     $what $time $how;
  -o-transition:      $what $time $how;
  transition:         $what $time $how;
}
.bx--btn--primary {
  @include transition(all, 0.5s, ease-in-out);
}
.bx--btn--primary:hover,
.bx--btn--primary:focus {
  box-shadow: none;
  background-color: $hover-color;
  @include transition(all, 0.5s, ease-in-out);
}
.bx--checkbox-label{
  font-size: 0.875rem;
};

.bx--checkbox-label::before{
  border-width: 2px;
  border-radius: 2px;
}

.bx--checkbox:focus + .bx--checkbox-label::before, 
.bx--checkbox-label__focus::before, 
.bx--checkbox:checked:focus + .bx--checkbox-label::before, 
.bx--checkbox-label[data-contained-checkbox-state='true'].bx--checkbox-label__focus::before, 
.bx--checkbox:indeterminate:focus + .bx--checkbox-label::before, 
.bx--checkbox-label[data-contained-checkbox-state='mixed'].bx--checkbox-label__focus::before{
  box-shadow: none
}
.bx--loading__stroke {
    stroke: $primary-color;
}
.bx--inline-loading__text {
  color: $primary-color;
}
.bx--list-box__menu-icon > svg {
  fill: $primary-color;
}
.bx--list-box__menu-item{
  border-bottom: 1px solid #F5F5F5;

  &:last-child{
    border-bottom: 0;
  }

  .bx--list-box__menu-item__option{
    font-size: $secondary-font-size;
    font-weight: $dropdown-item-font-weight; 
    color: $h1-color;
    border: none;
  }
}

.bx--list-box__menu-item:hover,
.bx--list-box__menu-item--active {
  background-color: $primary-color;
}
.bx--list-box__menu-item:hover .bx--list-box__menu-item__option,
.bx--list-box__menu-item--active .bx--list-box__menu-item__option {
  color: #ffffff;
}
.bx--dropdown:hover {
  background-color: #EBF2F7;
}
.bx--accordion__title {
  margin: 0;
}
.bx--accordion__arrow {
  flex: 0 0 1.5rem;
  margin: 2px 0rem 0 0;
}
.bx--accordion__content {
  padding: 0;
}
.bx--row{
  margin-left: -($gutter-width/2);
  margin-right: -($gutter-width/2);

  .bx--col{
    padding-left: $gutter-width/2;
    padding-right: $gutter-width/2;
  }
}
.bx--checkbox:checked + .bx--checkbox-label::before{
  background-color: $primary-color;
  border-color: $primary-color;
}