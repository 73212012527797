//App style variables
$primary-color:#008AC8;
$primary-font-size: 16px;
$secondary-font-size: 14px;
$header-font-size: 24px;
$header-line-height: 28px;
$subheader-font-size: 18px;
$subheader-line-height: 24px;
$paragraph-font-size: 14px;
$paragraph-line-height: 1.5;
$small-font-size: 9px;
$label-font-weight: 600;
$input-font-weight: 500;
$h1-color: #0f212e;
$h2-color: #0f212e;
$p-color: #6a7581;
$alert-color: #da1e28;
$hover-color: #0075AA;
$dropdown-item-font-weight: 500;
$gutter-width: 24px;

